import {Component, Inject, OnInit} from '@angular/core';
import {WebApiHttp} from "../../../../@pristine/process/WebApiHttp.services";
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {SessionManageMent} from "../../../../@pristine/process/SessionManageMent";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {NgxSpinnerService} from "ngx-spinner";
import {ToastrService} from "ngx-toastr";

@Component({
  selector: 'app-shiftandcalender',
  templateUrl: './shiftandcalender.component.html',
  styleUrls: ['./shiftandcalender.component.scss']
})
export class ShiftandcalenderComponent implements OnInit {

  shift: Array<any> = [];
  calender: Array<any> = [];
  shiftCalenderFormGroup:FormGroup;

  status:boolean=false;

  constructor(private webHttpApi: WebApiHttp,
              private fb: FormBuilder,
              private _session: SessionManageMent,
              private _dig: MatDialogRef<ShiftandcalenderComponent>,
              @Inject(MAT_DIALOG_DATA) public data:any,
              private spinner: NgxSpinnerService,
              private _toster: ToastrService) {

  this.shiftCalenderFormGroup = this.fb.group({
    shift: ['', Validators.required],
    calender: ['', Validators.required],

  });}
  ngOnInit(): void {
    this.getShift();
    this.getCalender();

  }
  getShift(){
    try {
      this.spinner.show();
      this.webHttpApi.Get(this.webHttpApi.ApiURLArray.getShiftMaster).then(
        result => {
          if (result[0].condition == 'True') {
            this.shift = result;
          } else {
            this._toster.error(result[0].message, 'Error');
          }
          this.spinner.hide();
        }
      ).catch(e => {
        this.spinner.hide();
      });
    } catch (e) {
      this.spinner.hide();
    }
  }
  getCalender(){
    try {
      this.spinner.show();
      this.webHttpApi.Get(this.webHttpApi.ApiURLArray.getCalenderMaster).then(
        result => {
          if (result[0].condition == 'True') {
            this.calender = result;
          } else {
            this._toster.error(result[0].message, 'Error');
          }
          this.spinner.hide();
        }
      ).catch(e => {
        this.spinner.hide();
      });
    } catch (e) {
      this.spinner.hide();
    }
  }

  addshiftcalender() {


    if (this.shiftCalenderFormGroup.valid) {

      // console.log(res);
      const shiftcalenderjson={
        shift:this.shiftCalenderFormGroup.get('shift').value,
        calender:this.shiftCalenderFormGroup.get('calender').value
      }
      this._dig.close(shiftcalenderjson);

    }
  }

}
