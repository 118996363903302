<div fxLayout="column" fxLayoutGap=" 10px">


  <mat-dialog-content [formGroup]="shiftCalenderFormGroup" fxLayout="column" fxLayoutGap="10px" fxFlex="1 0 auto"
                      fxLayoutAlign="space-between">
    <div fxLayout="row" fxLayoutGap="10px" >

      <mat-form-field fxFlex="45" appearance="outline">
        <mat-label>Shift</mat-label>
        <mat-select formControlName="shift">
          <mat-option *ngFor="let s of shift" [value]="s.shift_code">
            {{s.shift_code}}
          </mat-option>
        </mat-select>
      </mat-form-field>
      <mat-form-field fxFlex="45" appearance="outline">
        <mat-label>Calender</mat-label>
        <mat-select formControlName="calender">
          <mat-option *ngFor="let c of calender" [value]="c.calender_code">
            {{c.calender_code}}</mat-option>
        </mat-select>
      </mat-form-field>
    </div>

  </mat-dialog-content>
  <mat-dialog-actions fxLayout="row" fxLayoutAlign='end' fxLayoutGap="8px">
    <button mat-raised-button mat-button color="accent" (click)="addshiftcalender()" *ngIf="!status" [disabled]="shiftCalenderFormGroup.invalid"><mat-icon>add</mat-icon> Add</button>
    <button mat-raised-button mat-dialog-close>Cancel</button>
  </mat-dialog-actions>

</div>
